import React from 'react';
import { Component } from 'react';
import './AboutScreen.css';
import przemek from './../../../content/about/przemek.png';
import blazej from './../../../content/about/blazej.png';
import krzysiek from './../../../content/about/krzysiek.png';
import jacek from './../../../content/about/jacek.png';

export default class AboutScreen extends Component {
    public render() {
        return (
            <div className="about-container">
                <div className="row">
                    <div className="col">
                        <div className="title">
                            <p>Kim jesteśmy?</p>
                        </div>
                        <div className="separator">
                            <div className="dash" />
                            <div className="dot" />
                            <div className="dash" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="photos">
                            <div className="photo">
                                <img src={przemek} alt="przemek" />
                                <p className="img-label">Przemek</p>
                            </div>
                            <div className="photo">
                                <img src={blazej} alt="blazej" />
                                <p className="img-label">Błażej</p>
                            </div>
                            <div className="photo">
                                <img src={krzysiek} alt="krzysiek" />
                                <p className="img-label">Krzysiek</p>
                            </div>
                            <div className="photo">
                                <img src={jacek} alt="jacek" />
                                <p className="img-label">Jacek</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="description">
                            <p>Każdy z naszej czwórki specjalizuje się w swojej dziedzinie. Wspólnie tworzymy aplikację Mediti, która ma za zadanie pomóc ludziom być lepszą wersją siebie na co dzień.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}