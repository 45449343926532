import React from 'react';
import { Component } from 'react';
import './PriceScreen.css';
import { SimpleButton } from '../../Buttons/Simple/SimpleButton';

export default class PriceScreen extends Component {
    private openStoreWebsite() {
        window.open("https://multivoucher.pl/produkt/karta-podarunkowa-mediti/", "_blank");
    }

    public render() {
        let basicPrice = 39;
        let canUserBuyPacket = true;

        return (
            <div className="price-container">
                <div className="row">
                    <div className="col">
                        <div className="title">
                            <p className="title-desc">Sięgnij po pakiet Mediti Premium i uzyskaj dostęp do pełnej oferty Mediti</p>
                            <p className="title-header gold-text">Dostępne subskrypcje</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-4">
                        <div className="price-list-element">
                            <div className="price-list-element-header">
                                <p className="months">1 miesiąc</p>
                                <p className="price">{basicPrice} zł</p>
                            </div>
                            <div className="price-list-element-desc">
                                <ul>
                                    <li>Nielimitowany dostęp do pełnego katalogu medytacji Mediti</li>
                                    <li>Możliwość korzystania na 2 niezależnych urządzeniach</li>
                                    <li>Dostęp do Mediti na urządzeniach iOS oraz Android</li>
                                    <li>1,30 zł dziennie</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="price-list-element">
                            <div className="price-list-element-header">
                                <p className="months">3 miesiące</p>
                                <p className="price"><span className="crossed-text">{basicPrice * 3} zł</span> 99 zł</p>
                            </div>
                            <div className="price-list-element-desc">
                                <ul>
                                    <li>Nielimitowany dostęp do pełnego katalogu medytacji Mediti</li>
                                    <li>Możliwość korzystania na 2 niezależnych urządzeniach</li>
                                    <li>Dostęp do Mediti na urządzeniach iOS oraz Android</li>
                                    <li>1,10 zł dziennie</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="price-list-element">
                            <div className="price-list-element-header">
                                <p className="months">12 miesięcy</p>
                                <p className="price"><span className="crossed-text">{basicPrice * 12} zł</span> 320 zł</p>
                            </div>
                            <div className="price-list-element-desc">
                                <ul>
                                    <li>Nielimitowany dostęp do pełnego katalogu medytacji Mediti</li>
                                    <li>Możliwość korzystania na 2 niezależnych urządzeniach</li>
                                    <li>Dostęp do Mediti na urządzeniach iOS oraz Android</li>
                                    <li>0,88 zł dziennie</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="footer">
                            {(() => {
                                if (canUserBuyPacket) {
                                    return (
                                        <div>
                                            <p className="footer-text1">Chcesz uzyskać dostęp do Mediti Premium?</p>
                                            <div className="buy-now">
                                                <SimpleButton
                                                    text="Kup teraz"
                                                    onClick={(e) => this.openStoreWebsite()}
                                                />
                                            </div>
                                        </div>);
                                }
                                else {
                                    return (
                                        <div>
                                            <p className="footer-text1">Już wkrótce kody będą dostępne na www.multivoucher.pl</p>
                                        </div>);
                                }
                            })()}



                        </div>
                    </div>
                </div>
            </div>
        )
    }
}