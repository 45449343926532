import React from 'react';
import { Component } from 'react';
import './LectorScreen.css';
import lectorPrzemek from './../../../content/lector/przemek.png';

export default class LectorScreen extends Component {
    public render() {
        return (
            <div className="lector-container">
                <div className="row">
                    <div className="col">
                        <div className="lector-img">
                            <img src={lectorPrzemek} alt="lector-przemyslaw-bulski" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="lector-caption">
                            <p className="lector gold-text">Narrator</p>
                            <p className="lector-name">Przemysław Bulski</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="lector-desc">
                            <p>Hipnoterapeuta kliniczny, trener oddechu, medytacji, uważności i flow.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="lector-desc">
                            <p><span className="gold-text">Twórca nowatorskiej formy medytacji Mediti</span>, która stanowi połączenie różnych form uważności, technik oddechowych, autohipnozy, medytacji oraz technik duchowych.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}