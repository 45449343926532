import React from 'react';
import { Component } from 'react';
import { Header } from '../../Components/Header/Header';
import "./ForCompaniesPage.css";
import ContactScreen from '../../Components/Screens/Contact/ContactScreen';

type ForCompaniesPageProps = {
};

export default class ForCompaniesPage extends Component<ForCompaniesPageProps> {
    constructor(props: ForCompaniesPageProps) {
        super(props);
    }

    public render() {
        return (
            <div>
                <div className="web-container no-background">
                    <Header />

                    <section id="business" className="business">
                        <div className="business-container">
                            <div className="business-container-content">
                                <div className="row">
                                    <div className="col">
                                        <div className="title">
                                            <p>Mediti dla biznesu</p>
                                        </div>
                                        <div className="desc">
                                            <p className="desc-text">Dostęp do medytacji prowadzonej to wspaniały pomysł na benefity pozapłacowe dla pracowników.</p>
                                            <p className="desc-text"> Napisz do nas aby poznać ofertę Mediti dla firm.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="benefits" className="benefits">
                        <div className="benefits-container">
                            <div className="benefits-container-content">
                                <div className="row">
                                    <div className="col">
                                        <div className="title">
                                            <p>Korzyści dla Twojej firmy</p>
                                        </div>
                                        <div className="benefit-element">
                                            <p className="benefit-title gold-text">Prestiż</p>
                                            <p className="benefit-desc">Troska o rozwój pracownika to ważny element budowania marki pracodawcy.</p>
                                        </div>

                                        <div className="benefit-element">
                                            <p className="benefit-title gold-text">Elastyczność</p>
                                            <p className="benefit-desc">Możliwość wykupienia dostępu na 1,3 lub 12 miesięcy.</p>
                                        </div>

                                        <div className="benefit-element">
                                            <p className="benefit-title gold-text">Dostęp do nowości</p>
                                            <p className="benefit-desc">Baza medytacji Premium jest stale aktualizowana i rozwijana.</p>
                                        </div>

                                        <div className="benefit-element">
                                            <p className="benefit-title gold-text">Atrakcyjna cena</p>
                                            <p className="benefit-desc">Im więcej użytkowników, tym niższa cena jednostkowa.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="contact">
                        <div className="business-contact-container">
                            <ContactScreen
                                header="Poznaj ofertę Mediti dla firm! Napisz do nas"
                            />
                        </div>

                    </section>
                </div>
            </div>
        )

    }
}