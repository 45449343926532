import React from 'react';
import { Component } from 'react';
import './PasswordChangeScreen.css';
import { SimpleButton } from '../../Buttons/Simple/SimpleButton';
import apiRoutingService from '../../../Services/ApiRoutingService';

interface PasswordChangeScreenProps {
    token: string | null;
}

interface PasswordChangeScreenState {
    password1: string;
    password2: string;
    message: string;
    isSuccess: boolean;
    isLoading: boolean;
}

export default class PasswordChangeScreen extends Component<PasswordChangeScreenProps, PasswordChangeScreenState> {
    constructor(props: PasswordChangeScreenProps) {
        super(props);

        this.state = {
            password1: "",
            password2: "",
            message: "",
            isSuccess: true,
            isLoading: false
        }

        this.changePassword = this.changePassword.bind(this);
    }

    readonly successMessage = "Hasło zostało zaktualizowane";
    readonly samePasswords = "Hasła w obu polach muszą być identyczne";
    readonly passwordRequirements = "Hasło powinno mieć minimum sześć znaków, cyfrę, małą i wielką literę";
    readonly errorFromServer = "Nie udało się zmienić hasła, spróbuj później";
    readonly errorToken = "Link do zmiany hasła jest niepoprawny"
    readonly buttonText = "Ustaw nowe hasło";
    readonly buttonLoadingText = "Ustawiam...";


    private async changePassword() {
        let { password1, password2 } = this.state;
        let {token} = this.props;
        let shouldSendRequest = true;
        let message = this.successMessage;

        if (!this.isValidPassword(password1)) {
            shouldSendRequest = false;
            message = this.passwordRequirements;
        }

        if (password1 !== password2) {
            shouldSendRequest = false;
            message = this.samePasswords;
        }

        if (token === null) {
            shouldSendRequest = false;
            message = this.errorToken;
        }

        if (shouldSendRequest) {
            let isOk = await this.sendRequest(this.props.token!, password1);
            
            if (!isOk) {
                this.setState({ isSuccess: false, message: this.errorFromServer });
                return;
            }

            this.setState({ password1: "", password2: "", isSuccess: true, message });
        } else {
            this.setState({ isSuccess: false, message });
        }
    }

    private async sendRequest(token: string, password: string): Promise<boolean> {
        let result = false;

        this.setState({ isLoading: true });
        try {
            result = await apiRoutingService.changePassword(token, password);
        }
        catch { }
        this.setState({ isLoading: false });
        return result;
    }

    private isValidPassword(password: string): boolean {
        const minLength = 6;
        const regexLowercase = /[a-z]/;
        const regexUppercase = /[A-Z]/;
        const regexDigit = /\d/;

        return (
            password.length >= minLength &&
            regexLowercase.test(password) &&
            regexUppercase.test(password) &&
            regexDigit.test(password)
        );
    }

    private passwordInputChange(value: string, first: boolean) {
        if (first) {
            this.setState({
                password1: value
            });
        } else {
            this.setState({
                password2: value
            });
        }
    }

    public render() {
        let { isSuccess, message, password1, password2, isLoading } = this.state;
        let messageCss = isSuccess ? "message message-success" : "message message-error";

        return (
            <div className="password-change-container">
                <div className="row">
                    <div className="col">
                        <form id="contact-form">
                            <div className="form-group">
                                <input type="password" className="form-control" id="password1" name="password1" placeholder="Nowe hasło" value={password1} onChange={(e) => this.passwordInputChange(e.target.value, true)} />
                            </div>

                            <div className="form-group">
                                <input type="password" className="form-control" id="password2" name="password2" placeholder="Powtórz nowe hasło" value={password2} onChange={(e) => this.passwordInputChange(e.target.value, false)} />
                            </div>

                            <div className="form-group change-pass-button">
                                <SimpleButton
                                    text={isLoading ? this.buttonLoadingText : this.buttonText}
                                    onClick={this.changePassword}
                                />
                            </div>

                            <div className="form-group">
                                <p className={messageCss}>{message}</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}