export default class ApiRoutingService {

    static readonly apiUrl: string = "https://api.mediti.pl";
    static readonly apiToken: string = "90edcd2d9d84cbbe74556ebc5726e84f1e459b5deadcbd79472808adbd43290b";

    public static getMeditationsFetchUrl(): string {
        return `${this.apiUrl}/meditations-module/Meditations/`;
    }
    
    public static async changePassword(token: string, password: string): Promise<boolean> {
        let url = `${this.apiUrl}/users-module/Account/password-change/`;

        const data = {
            token: token,
            password: password
        };

        let response = await fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'XApiKey': `${this.apiToken}`,
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            return true;
        } else {
            const error = new Error("ERROR");
            return Promise.reject(error);
        }
    }
}