import React, { Component } from 'react';
import RoutingService from './Services/RoutingService';
import HomePage from './Pages/Home/HomePage';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import PasswordChangePage from './Pages/PasswordChange/PasswordChangePage';
import ForCompaniesPage from './Pages/ForCompanies/ForCompaniesPage';

type AppProps = {
};

type AppState = {
};

class App extends Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
        };
    }

    public render() {
        return (
            <div className="App">
                <BrowserRouter>

                    <Routes>
                        <Route path={RoutingService.getMainPageRoute()} element={<HomePage />} />
                        <Route path={RoutingService.getPasswordChangePageRoute()} element={<PasswordChangePage />} />
                        <Route path={RoutingService.getForCompaniesPageRoute()} element={<ForCompaniesPage />} />
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
