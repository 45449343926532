import React from 'react';
import { Component } from 'react';
import './DownloadScreen.css';
import { DownloadButtons } from '../../Buttons/Download/DownloadButtons';
import icon1 from './../../../content/download/sleep.png';
import icon2 from './../../../content/download/computer.png';
import icon3 from './../../../content/download/sunrise.png';
import icon4 from './../../../content/download/child.png';
import icon5 from './../../../content/download/no-smoke.png';
import icon6 from './../../../content/download/self-esteem.png';

export default class DownloadScreen extends Component {
    public render() {
        return (
            <div className="download-container">
                <div className="row">
                    <div className="col">
                        <div className="title">
                            <p>Pobierz <span className="gold-text">aplikację Mediti</span> i zyskaj dostęp do nagrań medytacyjnych dostosowanych do konkretnych sytuacji w życiu.</p>
                        </div>
                    </div>
                </div>

                <div className="screenshots">
                    <div className="row">
                        <div className="col-6 col-md-4">
                            <div className="screenshot">
                                <div className="img-container">
                                    <img src={icon1} alt="icon-sleep" />
                                </div>
                                <p className="icon-caption">Zdrowy i głęboki sen</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="screenshot">
                                <div className="img-container">
                                    <img src={icon2} alt="icon-computer" />
                                </div>
                                <p className="icon-caption">Relaks w pracy</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="screenshot">
                                <div className="img-container">
                                    <img src={icon3} alt="icon-sunrise" />
                                </div>
                                <p className="icon-caption">Udany początek dnia</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="screenshot">
                                <div className="img-container">
                                    <img src={icon4} alt="icon-child" />
                                </div>
                                <p className="icon-caption">Medytacja dla dzieci</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="screenshot">
                                <div className="img-container">
                                    <img src={icon5} alt="icon-nooke" />
                                </div>
                                <p className="icon-caption">Wolność od papierosów</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="screenshot">
                                <div className="img-container">
                                    <img src={icon6} alt="icon-self-esteem" />
                                </div>
                                <p className="icon-caption">Poczucie własnej wartości</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <DownloadButtons />
                    </div>
                </div>
            </div>
        )
    }
}