import React from 'react';
import { Component } from 'react';
import './AppScreen.css';
import screen1 from './../../../content/app/app-screen-1.png';
import screen2 from './../../../content/app/app-screen-2.png';
import screen3 from './../../../content/app/app-screen-3.png';
import screen4 from './../../../content/app/app-screen-4.png';
import screen5 from './../../../content/app/app-screen-5.png';

export default class AppScreen extends Component {
    public render() {
        return (
            <div className="app-container">
                <div className="row">
                    <div className="col">
                        <div className="title">
                            <p>Znajdź chwilę na <span className="gold-text">rozluźnienie</span> o każdej porze dnia i nocy.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="screenshots">
                        <div className="screenshot screenshot-1">
                                <img src={screen1} alt="app-screenshot-1" />
                            </div>
                            <div className="screenshot screenshot-2">
                                <img src={screen2} alt="app-screenshot-2" />
                            </div>
                            <div className="screenshot screen-top">
                                <img src={screen3} alt="app-screenshot-3" />
                            </div>
                            <div className="screenshot screenshot-4">
                                <img src={screen4} alt="app-screenshot-4" />
                            </div>
                            <div className="screenshot screenshot-5">
                                <img src={screen5} alt="app-screenshot-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}