import React, { useState, useEffect } from 'react';
import { SimpleButton } from '../Buttons/Simple/SimpleButton';
import { TextButton } from '../Buttons/Text/TextButton';
import logo from './../../content/Mediti.svg';
import './Header.css';
import menuIconOpened from './../../content/header/menu-opened.svg';
import menuIconClosed from './../../content/header/menu-closed.svg';
import RoutingService from '../../Services/RoutingService';

export const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleOpenMenu = () => {
    let button = document.getElementById("menu-button");
    let navbar = document.getElementById("navbar");
    //if false then menu is opened
    if (!(button?.classList.contains("collapsed"))) {
      navbar?.classList.add("opened");
    } else {
      navbar?.classList.remove("opened");
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.getElementById("menu-button")?.addEventListener('click', handleOpenMenu);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.getElementById("menu-button")?.removeEventListener('click', handleOpenMenu);
    };
  }, []);

  return (
    <nav id="navbar" className={`navbar navbar-expand-lg fixed-top header ${scrolled ? 'scrolled' : ''}`}>

        <a className="navbar-brand" onClick={e => goToOrScrollTo(e, "start")}>
          <div className="mediti-logo">
            <img src={logo} alt="Mediti" />
          </div>
        </a>

        <button id="menu-button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <img src={menuIconClosed} className="navbar-toggler-icon-menu" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">

            <li className="nav-item">
              <a className="nav-link">
                <TextButton
                  text="Cennik"
                  onClick={(e) => goToOrScrollTo(e, "price")}
                />
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link">
                <TextButton
                  text="O nas"
                  onClick={e => goToOrScrollTo(e, "about")}
                />
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link">
                <span className="company-button">
                  <SimpleButton
                    text={"Dla firm"}
                    onClick={e => goTo(e, RoutingService.getForCompaniesPageRoute(), null)}
                  />
                </span>
              </a>
            </li>

          </ul>
        </div>
    </nav>
  );
};

function goToOrScrollTo(e: any, section: string) {
  if(window.location.pathname.length > 1) {
    goTo(e, RoutingService.getMainPageRoute(), section);
  } else {
    scrollTo(e, section);
  }
}


function goTo(e: any, url: string, section: string | null) {
  e.preventDefault();

  let urlToGo = url;
  if (section !== null) {
    urlToGo += "#" + section;
  }

  window.location.replace(urlToGo);
}

function scrollTo(e: any, section: string) {
  scrollToSectionWithOffset(section, 50);
}

function scrollToSectionWithOffset(targetId: string, offset: number) {
  var targetElement = document.getElementById(targetId);
  
  if (targetElement) {
      var targetPosition = targetElement.offsetTop - offset;
      
      window.scrollTo({
          top: targetPosition,
          behavior: 'smooth'
      });
  }
}