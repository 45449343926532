import React from 'react';
import { Component } from 'react';
import './ReviewsScreen.css';

export default class ReviewsScreen extends Component {
    public render() {
        return (
            <div className="reviews-container">
                <div className="row">
                    <div className="col">
                        <div className="title">
                            <p>Zadowoleni <span className="gold-text">Medytujący</span></p>
                        </div>
                    </div>
                </div>
                <div className="review-imgs">
                    <div className="row">
                        <div className="col">
                            <div className="review-img">
                                <img className="review1" alt="review-img-1" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="review-img">
                                <img className="review2" alt="review-img-2" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="review-img">
                                <img className="review3" alt="review-img-3" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="review-img">
                                <img className="review4" alt="review-img-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}