import googlePlay from './../../../content/download-googleplay.svg';
import appStore from './../../../content/download-appstore.svg';
import './DownloadButtons.css';

const googlePlayLink = "https://play.google.com/store/apps/details?id=mediti.android.main";
const appStoreLink = "https://apps.apple.com/pl/app/mediti/id1625582645";

export const DownloadButtons = () => {
    return (
        <div className="download-buttons">
            <a className="button google" href={googlePlayLink}>
                <img src={googlePlay} alt="Google Play" />
            </a>
            <a className="button apple" href={appStoreLink}>
                <img src={appStore} alt="AppStore" />
            </a>
        </div>
    );
}