import React from 'react';
import { Component } from 'react';
import { Header } from '../../Components/Header/Header';
import "./PasswordChangePage.css";
import PasswordChangeScreen from '../../Components/Screens/PasswordChange/PasswordChangeScreen';
import ContactScreen from '../../Components/Screens/Contact/ContactScreen';

type PasswordChangePageProps = {
};

export default class PasswordChangePage extends Component<PasswordChangePageProps> {
    constructor(props: PasswordChangePageProps) {
        super(props);
    }

    public render() {
        const searchParams = new URLSearchParams(window.location.search);
        let token = searchParams.get('token')
        return (
            <div>
                <div className="background"></div>
                <div className="web-container">
                    <Header />

                    <section id="forgotten-password">
                        <PasswordChangeScreen token={token} />
                    </section>

                    <section id="contact">
                        <ContactScreen
                        header="Skontaktuj się z nami"
                        />
                    </section>
                </div>
            </div>
        )

    }
}