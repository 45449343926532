import React from 'react';
import { Component } from 'react';
import StartScreen from '../../Components/Screens/Start/StartScreen';
import AppScreen from '../../Components/Screens/App/AppScreen';
import DownloadScreen from '../../Components/Screens/Download/DownloadScreen';
import ContactScreen from '../../Components/Screens/Contact/ContactScreen';
import LectorScreen from '../../Components/Screens/Lector/LectorScreen';
import { Header } from '../../Components/Header/Header';
import "./HomePage.css";
import BookScreen from '../../Components/Screens/Book/BookScreen';
import MoreScreen from '../../Components/Screens/More/MoreScreen';
import PriceScreen from '../../Components/Screens/Price/PriceScreen';
import ReviewsScreen from '../../Components/Screens/Reviews/ReviewsScreen';
import AboutScreen from '../../Components/Screens/About/AboutScreen';

export default class HomePage extends Component {
    public render() {
        return (
            <div>
                <div className="web-container">
                    <Header />

                    <div className="bg-1">
                        <section id="start">
                            <StartScreen />
                        </section>

                        <section id="app">
                            <AppScreen />
                        </section>
                    </div>

                    <section id="lector">
                        <LectorScreen />
                    </section>

                    <div className="bg-2">
                        <section id="ebook">
                            <BookScreen />
                        </section>

                        <section id="download">
                            <DownloadScreen />
                        </section>

                        <section id="more">
                            <MoreScreen />
                        </section>
                    </div>

                    <section id="price">
                        <PriceScreen />
                    </section>

                    <div className="bg-3">
                        <section id="reviews">
                            <ReviewsScreen />
                        </section>

                        <section id="about">
                            <AboutScreen />
                        </section>

                        <section id="contact">
                            <ContactScreen
                                header="Skontaktuj się z nami"
                            />
                        </section>
                    </div>
                </div>
            </div>
        )

    }
}