import React from 'react';
import { Component } from 'react';
import './MoreScreen.css';

export default class MoreScreen extends Component {
    public render() {
        return (
            <div className="more-container">
                <div className="row">
                    <div className="col">
                        <div className="title">
                            <p>Dowiedz się więcej o Mediti</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="main-video">
                            <iframe width="672" height="378" src="https://www.youtube.com/embed/x_mLravk1a8?si=E4dFI4jP1EJjX7Hd" title="Abount Mediti" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            <p className="video-caption">O metodzie Mediti</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="sub-video">
                        <iframe width="392" height="220" src="https://www.youtube.com/embed/qTCzfSDK-Ns?si=PI6EiMo53Q1ypN8k" title="How to choose recordings" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            <p className="video-caption">Jak dobierać nagrania</p>
                        </div>
                    </div>
                    <div className="col">
                    <div className="sub-video">
                    <iframe width="392" height="220" src="https://www.youtube.com/embed/rjl4hf6AQAY?si=FIi_dacgkHrLaatS" title="The correct position in meditation" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            <p className="video-caption">Prawidłowa pozycja w medytacji</p>
                        </div>
                    </div>
                    <div className="col">
                    <div className="sub-video">
                    <iframe width="392" height="220" src="https://www.youtube.com/embed/yUAe6qWltX4?si=Ge2TZ5O2Zb2_WtHy" title="Benefits of meditation" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            <p className="video-caption">Korzyści z medytacji</p>
                        </div>
                    </div>
                    <div className="col">
                    <div className="sub-video">
                    <iframe width="392" height="220" src="https://www.youtube.com/embed/j3Kg1P7vW8Y?si=Ue7ASAH1MxOmIl-d" title="How long does it take to notice the benefits" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            <p className="video-caption">Ile czasu potrzeba aby zauważyć korzyści</p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}