export default class RoutingService {
    static readonly startQueryChar: string = "?";
    static readonly querySeparator: string = "&";

    public static getMainPageRoute(): string {
        return `/`;
    }
    
    public static getPasswordChangePageRoute(): string {
        return `/passwordChange/`;
    }

    public static getPasswordChangeRoute(token: string): string {
        return `/passwordChange/${this.startQueryChar}token=${token}`;
    }

    public static getForCompaniesPageRoute(): string {
        return `/for-companies/`;
    }
}