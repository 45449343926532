import { Component } from 'react';
import { SimpleButton } from '../../Buttons/Simple/SimpleButton';
import emailjs, { init } from 'emailjs-com';
import './ContactScreen.css';
import React from 'react';

interface ContactScreenProps {
    header: string;
}

interface ContactScreenState {
    showForm: boolean;
    emailButtonText: string;
    sendingError: string;
}

export default class ContactScreen extends Component<ContactScreenProps, ContactScreenState> {
    readonly emailSend = "Wyślij";
    readonly emailSending = "Wysyłam...";
    readonly emailDelivered = "Dostarczono";

    constructor(props: ContactScreenProps) {
        super(props);

        this.state = {
            showForm: false,
            emailButtonText: this.emailSend,
            sendingError: ""
        }

        this.sendMail = this.sendMail.bind(this);
        this.trySendMail = this.trySendMail.bind(this);

        init("user_TOjbEjaposCsCt6KU7hhA");
    }

    private sendMail() {
        this.setState({
            emailButtonText: this.emailSending,
            sendingError: ""
        });

        let buttonText = this.emailDelivered;
        let errMsg = "";

        var form = document.getElementById("contact-form") as HTMLFormElement;

        emailjs.sendForm('service_llqk3va', 'template_2bgjvxd', form, 'user_TOjbEjaposCsCt6KU7hhA')
            .then((result) => {
                console.log(result.text);

                this.setState({
                    emailButtonText: buttonText,
                    sendingError: errMsg
                });
            }, (error) => {
                console.log(error.text);
                buttonText = this.emailSend;
                errMsg = "Nie udało się wysłać wiadomości. Sprobuj ponownie później.";

                this.setState({
                    emailButtonText: buttonText,
                    sendingError: errMsg
                });
            });
    }

    public trySendMail() {
        let { emailButtonText } = this.state;

        if (emailButtonText === this.emailDelivered) {
            console.log("mail already delivered.");
            return;
        }

        this.sendMail();
    }

    public render() {
        return (
            <div className="contact-container">
                <div className="row">
                    <div className="col">
                        <div className="form-container">
                            <p className="contant-us">{this.props.header}</p>
                            <form id="contact-form">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="user_name" name="user_name" placeholder="Nazwa" />
                                </div>

                                <div className="form-group">
                                    <input type="email" className="form-control" id="user_email" name="user_email" placeholder="Email" />
                                </div>

                                <div className="form-group">
                                    <textarea className="form-control" id="form_text" name="form_text" rows={5} placeholder="Treść wiadomości"></textarea>
                                </div>

                                <div className="form-group">
                                    <SimpleButton
                                        text={this.state.emailButtonText}
                                        onClick={this.trySendMail}
                                    />
                                </div>

                                <div className="form-group">
                                    <p className="mail-error">{this.state.sendingError}</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

