import './SimpleButton.css';

interface SimpleButtonProps {
    text: string;
    big: boolean;
    onClick: (e: any) => void;
}

export const SimpleButton = (props: SimpleButtonProps) => {
    let css = "simple-button";

    if (props.big) {
        css += " big-button";
    }

    return (
        <span className={css} onClick={(e) => props.onClick(e)}>{props.text}</span>
    );
}

SimpleButton.defaultProps = {
    big: false,
    onClick: (e: any) => void(0)
}