import React from 'react';
import { Component } from 'react';
import './BookScreen.css';
import bookCover from './../../../content/book/book-cover.png';
import { SimpleButton } from '../../Buttons/Simple/SimpleButton';

export default class BookScreen extends Component {

    donwloadEbook(e: any) {
        e.preventDefault();
        window.open("https://storage.googleapis.com/mediti-static/ujarzmij-stres.zip", "_blank");
    }
    
    public render() {
        return (
            <div className="book-container">
                <div className="row">

                    <div className="col">
                        <div className="book-desc">
                            <p>Pobierz <span className="gold-text">bezpłatnego ebooka</span> i dowiedz się więcej na temat sposobów radzenia sobie ze stresem.</p>
                            <div className="company-button">
                                <SimpleButton
                                    text={"Pobierz"}
                                    onClick={e => this.donwloadEbook(e)}
                                />
                            </div>
                        </div>
                        <div className="book-cover">
                            <img src={bookCover} alt="ujarzmij-stres-ebook" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}