import './TextButton.css';

interface TextButtonProps {
    text: string;
    onClick: (e: any) => void;
}

export const TextButton = (props: TextButtonProps) => {
    return (
        <span className="text-button" onClick={(e) => props.onClick(e)}>{props.text}</span>
    );
}

TextButton.defaultProps = {
    onClick: (e: any) => void(0)
}